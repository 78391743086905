export const defaultLocale = "id";
export const defaultTheme = 'light';


export const appName = "ELEMEN";
export const shortCompanyName = "Elektronik Memo Nusantara";
export const appVersion = "3.0.0";
export const companyName = "PT Perkebunan Nusantara IV";
export const testingPurpose = false;

export const baseUrl = "https://ujielemen.ptpn.id/service"; //dev only`
export const echoUrl = 'https://elemen.ptpn.id:8893'; //prod
export const apiUrl = `${baseUrl}/api`;
//export const echoUrl = window.location.hostname +':6001'; //devBlades0409A

export const echoAppKey = 'haNkCLVr99UMTTqY731rsAwr3ST9HMXJ';
export const echoAppId = 'fZjI86UcC01w9fqq';

export const vapidPublicKey = 'BMG2UDqKTFx2u_8ZQ4VkVzLydefdPpO3X1nv8VO7_sTaz14ydlN_yjlHPW-vBF9WpEKkeWKrT9KjP3qLbHOtPVo';
export const rowPerPage = 50;
export const isAuthGuardActive = true;
export const isIntroActive = false;
export const sendDeviceLocation = false;

export const localeOptions = [
  { id: "id", name: "Indonesia" },
  { id: "en", name: "English" }
];


export const notification = {
  title: `${appName}`,
  options: {
    body: '🔔 Permintaan Revisi Sejawat\n✉️ : Ini Adalah Contoh Hal Memo\n🧑🏻 ‍: Vadila Winanda.\nSilahkan ke laman web untuk selengkapnya.',
    vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
    // image: `${process.env.BASE_URL}assets/img/background/notification_bg.jpg`,
    icon: `${process.env.BASE_URL}assets/img/logo.png`,
    requireInteraction: true,
  },

  events: {
    onerror: function () {
      console.error('Custom error event was called');
    },
    onclick: function () {
      // event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open('https://elemenx.ptpn.id/memo/draft', '_blank');
    },
    onclose: function () {
      console.log('Custom close event was called');
    },
    onshow: function () {
      console.log('show it');
    }
  },
};
